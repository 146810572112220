<template>
  <v-app class="app-content">
    <router-view />
  </v-app>
</template>

<script>
export default {
  mounted() {
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
    }
    this.$vuetify.locale.current = localStorage.getItem("locale");
  },
};
</script>
